@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin img-size {
    height: 100%;
    width: 100%;
}

@mixin icon-vector {
    height: 2rem;
    width: 2rem;
}
$laptop-land: 75em; //1200px / 16px = 75em
$laptop-port: 64em; //1024px / 16px = 64em
$tab-land: 62em; //992px / 16px = 62em
$tab-port: 48em; //768px / 16px = 48em
$mbl-land: 35.9375em; //575px / 16px = 35.9375em
$mbl-port: 30em; //480px / 16px = 30em
$mbl-small: 23.75em; //380px / 16px = 23.75em
.modal_portfolio {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 99;

    .modal__outside {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 3;
    }

    .modal__content {
        max-width: 680px;
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #252525;
        padding: 2rem;
        border-radius: 1rem;
        z-index: 5;
        @media (max-width: 576px) {
            padding: 15px;
        }

        h2 {
            color: var(--main-primary-color);
            text-transform: uppercase;
            padding: 10px 0 20px;
            font-weight: 700;
            text-align: center;
            font-size: 33px;
            margin: 0;
            @media (max-width: 576px) {
                font-size: 22px;
            }
        }
    }
    .modal__details {
        margin-bottom: 25px;
        
        .text {
            color: #fff;
        }

        .preview-link {
            color: var(--main-primary-color);
            text-decoration: underline;
            font-size: 15px;
            font-weight: 600;
        }
    }
    .modal__img {
        border-radius: 10px;
        overflow: hidden;
        background: #252525;
        margin: 0;
        margin-bottom: 5px;
        height: 25%;
        width: 25%;

        img,
        video,
        .slick-slider,
        iframe {
            @include img-size;
            border-radius: 0.5rem;
        }
    }
    .slick-slider {
        .slick-arrow {
            display: none !important;
        }

        .slick-dots {
            bottom: 17px;
            background: rgba(0, 0, 0, 0.4);
            padding: 10px;
            border-radius: 0 0 10px 10px;
            @media screen and (max-width: 991px) {
                bottom: 6px;
            }

            button {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                position: relative;

                &::before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    transition: 0.3s ease-in-out;
                    border: 1px solid var(--main-primary-color);
                    border-radius: 50%;
                    opacity: 1;
                }

                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    transform: scale(0);
                    background: var(--main-primary-color);
                    border-radius: inherit;
                    z-index: 5;
                    content: "";
                    width: 100%;
                    height: 100%;
                    transition: 0.3s ease-in-out;
                }
            }

            li.slick-active button:after {
                transform: scale(0.6);
            }
        }

        .slick-list,
        .slick-track {
            height: 100%;
            div {
                height: 100%;
            }
        }
    }

    .close-modal {
        position: absolute;
        top: -52px;
        right: -86px;
        background-color: transparent;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @media screen and (max-width: 575px) {
            width: 30px;
            height: 30px;
            right: -16px;
            top: -51px;
        }

        img {
            @include img-size;
            filter: invert(0.8);
            &:hover {
                filter: invert(1);
            }
        }
    }
    .videocontainer {
        position: relative;
        padding-bottom: 56.2%;
        padding-top: 0;
        height: 0;
        max-width: 100%;
        overflow: hidden;
        width: 100%;
        display: block;
        margin: 0;
        border-radius: 4px;
        @media screen and (max-width: 575px) {
            padding-bottom: 65%;
        }
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
}
